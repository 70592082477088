export const PRIMARY_BUTTON = 'primary';
export const SECONDARY_BUTTON = 'secondary';
export const WARNING_BUTTON = 'warning';
export const WARNING_SECONDARY_BUTTON = 'warning-secondary';
export const RM_BUTTON = 'monitoring';

export const DOCTOR_SIGN_TYPE = {
  0: 'Нет',
  1: 'Мать и Дитя',
  2: 'FoodFessional',
  3: 'Evogen',
  4: 'РТ Здоровье',
};

export const SEX_TYPE = {
  1: 'Мужской',
  2: 'Женский',
};

export const CONTACT_TYPE = [
  {
    title: 'Контакты для записи',
    type: 'appointmentContacts',
    index: 1,
  },
  {
    title: 'Контакты для рассылки',
    type: 'legalContacts',
    index: 2,
  },
  {
    title: 'Гарантийные контакты',
    type: 'indemnityContact',
    index: 3,
  },
  {
    title: 'Контакты для клиентского сервиса',
    type: 'clientServiceContact',
    index: 4,
  },
];

export class POLICY_TYPE {
  static types = {
    0: 'DMS',
    1: 'OMS',
    2: 'All',
  }

  static decodeType(type) {
    return this.types[type];
  }

  static codeType(type) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(this.types)) {
      if (type === value) {
        return parseInt(key, 10);
      }
    }
  }
}

export const dutyDoctorsIds = [1, 2, 3, 4, 5];

export const DOCTOR_ACCESS_TYPE = {
  0: 'Виден всем',
  1: 'Пациентам из клиники врача',
  2: 'Врачам клиники при повторной записи',
  3: 'Только в определенной подпрограмме',
};

export const CLINIC_SERVICES = {
  0: 'ПНД',
  1: 'Полный стационар',
  2: 'Дневной стационар',
  3: 'Роды',
  4: 'Телемед',
  5: 'ЭКО',
  6: 'Ведение беременности',
  7: 'Онкология',
  8: 'АПП',
};

export const CLINIC_PRIORITIES = [
  {
    title: 'Не выбран',
    index: 0,
  },
  {
    title: 'Приоритетная',
    index: 1,
  },
  {
    title: 'Не определен',
    index: 2,
  },
  {
    title: 'Не отправляем',
    index: 3,
  },
];

export const CONSULTATION_STATUSES = [
  {
    title: 'Новая',
    index: 0,
  },
  {
    title: 'В работе',
    index: 1,
  },
  {
    title: 'Закрыта',
    index: 2,
  },
  {
    title: 'Консилиум',
    index: 3,
  },
  {
    title: 'Ожидает оплаты',
    index: 4,
  },
  {
    title: 'Отменена',
    index: 6,
  },
  {
    title: 'Таймаут оплаты',
    index: 7,
  },
  {
    title: 'Отменена пациентом',
    index: 8,
  },
  {
    title: 'Отменена врачом',
    index: 9,
  },
  {
    title: 'Забронировано',
    index: 10,
  },
  {
    title: 'Отмена бронирования',
    index: 11,
  },
];

export const CONSULTATION_STATUSES_FOR_CLINIC_ADMIN = [
  {
    title: 'Новая',
    index: 0,
  },
  {
    title: 'В работе',
    index: 1,
  },
  {
    title: 'Закрыта',
    index: 2,
  },
  {
    title: 'Отменена',
    index: 6,
  },
  {
    title: 'Отменена пациентом',
    index: 8,
  },
  {
    title: 'Отменена врачом',
    index: 9,
  },
];

export const CONSULTATION_TYPES = [
  {
    title: 'Пациент-Врач',
    index: 0,
  },
  {
    title: 'Врач-Врач',
    index: 1,
  },
];

export const APPEAL_STATUSES = [
  {
    title: 'Передано',
    index: 0,
  },
  {
    title: 'Отложено',
    index: 1,
  },
  {
    title: 'Закрыто',
    index: 2,
  },
];

export const KNOWLEDGE_BASE_SECTIONS = [
  {
    name: ['Контакты', 'Пароли'],
    svgComponent: 'IconContactBook',
    link: 'contacts-passwords',
    type: 0,
  },
  {
    name: ['Коммерческий Доктис'],
    svgComponent: 'IconTelemed',
    link: 'commercial-doctis',
    type: 1,
  },
  {
    name: ['Гостелемед'],
    svgComponent: 'IconGosUslugi',
    link: 'gostelemed',
    type: 2,
  },
  {
    name: ['Конфлюенс'],
    svgComponent: 'IconCommunity',
    link: 'confluence',
    type: 3,
  },
  {
    name: ['Регламенты, Инструкции', 'Алгоритмы'],
    svgComponent: 'IconInstructions',
    link: 'regulations-instructions-algorithms',
    type: 4,
  },
  {
    name: ['Стандарты обслуживания клиентов', 'Чеклисты'],
    svgComponent: 'IconChecklist',
    link: 'service-checklists',
    type: 5,
  },
  {
    name: ['Обучение'],
    svgComponent: 'IconBook',
    link: 'training',
    type: 6,
  },
];

export const MONTHS = [
  {
    id: 1,
    ru: 'Январь',
    en: 'January',
  },
  {
    id: 2,
    ru: 'Февраль',
    en: 'February',
  },
  {
    id: 3,
    ru: 'Март',
    en: 'March',
  },
  {
    id: 4,
    ru: 'Апрель',
    en: 'April',
  },
  {
    id: 5,
    ru: 'Май',
    en: 'May',
  },
  {
    id: 6,
    ru: 'Июнь',
    en: 'June',
  },
  {
    id: 7,
    ru: 'Июль',
    en: 'July',
  },
  {
    id: 8,
    ru: 'Август',
    en: 'August',
  },
  {
    id: 9,
    ru: 'Сентябрь',
    en: 'September',
  },
  {
    id: 10,
    ru: 'Октябрь',
    en: 'October',
  },
  {
    id: 11,
    ru: 'Ноябрь',
    en: 'November',
  },
  {
    id: 12,
    ru: 'Декабрь',
    en: 'December',
  },
];

export const DOMAIN_SETTINGS = {
  gostelemed: {
    logo: 'gostelemed-logo',
    title: 'Гостелемед',
    favicon: 'gostelemed-favicon',
    phone: '8 800 222 6164',
  },
  altay: {
    logo: 'altay-logo',
    title: '',
    favicon: 'altay-favicon',
    phone: '8 800 222 6164',
  },
  ekat: {
    logo: 'ekat-logo',
    title: '',
    favicon: 'ekat-favicon',
    phone: '8 800 222 6164',
  },
  dagestan: {
    logo: 'dagestan-logo',
    title: 'Дагестан',
    favicon: 'dagestan-favicon',
    phone: '8 800 222 6164',
  },
  yamal: {
    logo: 'yamal-logo',
    title: 'ЯНАО',
    favicon: 'yamal-favicon',
    phone: '8 800 222 6164',
  },
  penza: {
    logo: 'penza-logo',
    title: '',
    favicon: 'penza-favicon',
    phone: '8 800 222 6164',
  },
  kurgan: {
    logo: 'kurgan-logo',
    title: '',
    favicon: 'kurgan-favicon',
    phone: '8 800 222 6164',
  },
  fmba: {
    logo: 'fmba-logo',
    title: 'ФМБА',
    favicon: 'fmba-favicon',
    phone: '8 800 700 48 97',
  },
  buryatia: {
    logo: 'buryatia-logo',
    title: 'Бурятия',
    favicon: 'buryatia-favicon',
    phone: '8 800 222 6164',
  },
  nino: {
    logo: 'nino-logo',
    title: '',
    favicon: 'nino-favicon',
    phone: '8 800 222 6164',
  },
  msk: {
    logo: 'msk-logo',
    title: 'Наша поликлиника',
    favicon: 'msk-favicon',
    phone: '8 800 222 6164',
  },
  astrakhan: {
    logo: 'astrakhan-logo',
    title: 'Астрахань',
    favicon: 'astrakhan-favicon',
    phone: '8 800 222 6164',
  },
  // kaluga: {
  //   logo: 'kaluga-logo',
  //   title: 'Калуга',
  //   favicon: 'kaluga-favicon',
  //   phone: '8 800 222 6164',
  // },
  mariel: {
    logo: 'mariel-logo',
    title: 'Марий Эл',
    favicon: 'mariel-favicon',
    phone: '8 800 222 6164',
  },
};

export const CERTIFICATE_TYPE = {
  0: 'None',
  2: 'DoctisPatient',
  3: 'DoctisPatientVoip',
  4: 'DoctisDoctor',
  10: 'MyHelmet',
  12: 'MyHelmetVoip',
  14: 'FoodFessional',
  16: 'FoodFessionalVoip',
  18: 'MyHealth',
  20: 'MyHealthVoip',
  22: 'Gostelemed',
  24: 'GostelemedVoip',
};

export const CLIENT_TYPE = {
  0: 'Unknown',
  1: 'DoctorApp',
  2: 'PatientApp',
  3: 'AdminApp',
  4: 'Crm',
};

export const CLIENT_PLATFORM = {
  0: 'Unknown',
  1: 'DoctisAndroid',
  2: 'DoctisIos',
  4: 'MyHelmetIos',
  5: 'MyHelmetAndroid',
  6: 'Web',
  7: 'FoodFessionalIos',
  8: 'FoodFessionalAndroid',
  9: 'MyHealthIos',
  10: 'MyHealthAndroid',
  11: 'GostelemedIos',
  12: 'GostelemedAndroid',
  13: 'Telemed52Ios',
  14: 'Telemed52Android',
  15: 'RtHealthIos',
  16: 'RtHealthAndroid',
  17: 'TelemedDagestanIos',
  18: 'TelemedDagestanAndroid',
  19: 'TelemedBuryatiaIos',
  20: 'TelemedBuryatiaAndroid',
  21: 'MoscowRegionIos',
  22: 'MoscowRegionAndroid',
  23: 'TelemedFmbaIos',
  24: 'TelemedFmbaAndroid',
};

export const ROUTE_COMPONENTS = {
  '/doctors': {
    path: '/doctors',
    component: () => import('@/pages/Doctors'),
  },
  '/clinics': {
    path: '/clinics',
    component: () => import('@/pages/Clinics'),
  },
  '/clinics/:clinicId': {
    path: '/clinics/:clinicId',
    component: () => import('@/components/Clinics/ClinicPage/ClinicPage'),
    props(route) {
      return { clinicId: route.params.clinicId };
    },
  },
  '/clinics-regions': {
    path: '/clinics-regions',
    component: () => import('@/components/Clinics/Regions/RegionsPage'),
  },
  '/insurance-programs/program/subprogram-create-oms': {
    path: '/insurance-programs/program/subprogram-create-oms',
    component: () => import('@/components/InsurancePrograms/InsuranceSubprogramCreatePageOMS'),
  },
  '/insurance-programs/program/subprogram-create-dms': {
    path: '/insurance-programs/program/subprogram-create-dms',
    component: () => import('@/components/InsurancePrograms/InsuranceSubprogramCreatePageDMS'),
  },
  '/insurance-companies': {
    path: '/insurance-companies',
    component: () => import('@/pages/InsuranceCompanies'),
  },
  '/insurance-import': {
    path: '/insurance-import',
    component: () => import('@/components/InsurancePrograms/InsuranceImport'),
  },
  '/insurance-companies/new': {
    path: '/insurance-companies/new',
    component: () => import('@/components/InsurancePrograms/InsuranceCompanyEdit'),
  },
  '/insurance-companies/:company/edit': {
    path: '/insurance-companies/:company/edit',
    component: () => import('@/components/InsurancePrograms/InsuranceCompanyEdit'),
  },
  '/insurance-companies/:company/programs': {
    path: '/insurance-companies/:company/programs',
    component: () => import('@/components/InsurancePrograms/InsuranceProgramPage'),
  },
  '/insurance-companies/:company/programs/:program/subprograms/:subprogram': {
    path:
      '/insurance-companies/:company/programs/:program/subprograms/:subprogram',
    component: () => import('@/components/InsurancePrograms/InsuranceSubprogramDetail'),
  },
  '/insurance-companies/:company/programs/:program/subprograms/:subprogram/edit': {
    path:
      '/insurance-companies/:company/programs/:program/subprograms/:subprogram/edit',
    component: () => import('@/components/InsurancePrograms/InsuranceSubprogramEdit'),
  },
  '/insurance-companies/:company/subprograms/new': {
    path: '/insurance-companies/:company/subprograms/new',
    component: () => import('@/components/InsurancePrograms/InsuranceSubprogramEdit'),
  },
  '/consultation-feedback': {
    path: '/consultation-feedback',
    component: () => import('@/pages/ConsultationFeedback'),
  },
  '/patients': {
    path: '/patients',
    component: () => import('@/pages/Patients'),
  },
  '/family-policies': {
    path: '/family-policies',
    component: () => import('@/pages/FamilyPolicies'),
  },
  '/appeals': {
    path: '/appeals',
    component: () => import('@/pages/Appeals'),
  },
  '/patients/import': {
    path: '/patients/import',
    component: () => import('@/components/InsurancePrograms/InsuranceImport'),
  },
  '/statistics': {
    path: '/statistics',
    component: () => import('@/pages/Statistics'),
  },
  '/insurance-companies/import': {
    path: '/insurance-companies/import',
    component: () => import('@/components/InsurancePrograms/InsuranceProgramReestrImport'),
  },
  '/reports': {
    path: '/reports',
    component: () => import('@/pages/Reports'),
  },
  '/laboratory-partners': {
    path: '/laboratory-partners',
    component: () => import('@/pages/LaboratoryPartners'),
  },
  '/laboratory-promocodes': {
    path: '/laboratory-promocodes',
    component: () => import('@/pages/LaboratoryPromocodes'),
  },
  '/laboratory-tags': {
    path: '/laboratory-tags',
    component: () => import('@/pages/LaboratoryTags'),
  },
  '/laboratory-legal-partners': {
    path: '/laboratory-legal-partners',
    component: () => import('@/pages/LaboratoryLegalPartners'),
  },
  '/laboratory-partner-service': {
    path: '/laboratory-partner-service',
    component: () => import('@/pages/LaboratoryPartnerService'),
  },
  '/import-partner-prices': {
    path: '/import-partner-prices',
    component: () => import('@/components/Laboratories/ImportPartnerPrices'),
  },
  '/laboratory-partner-service/bind/:id': {
    path: '/laboratory-partner-service/bind/:id',
    component: () => import('@/components/Laboratories/PartnerServiceBind'),
    props(route) {
      return { partnerServiceId: route.params.id };
    },
  },
  '/laboratory-bind-service': {
    path: '/laboratory-bind-service',
    component: () => import('@/pages/LaboratoryBindServiceRedirectPage'),
  },
  '/laboratory-bind-service/:id': {
    path: '/laboratory-bind-service/:id',
    component: () => import('@/pages/LaboratoryBindServicePage'),
    props(route) {
      return { partnerServiceId: route.params.id };
    },
  },
  '/laboratory-branches': {
    path: '/laboratory-branches',
    component: () => import('@/pages/LaboratoryBranches'),
  },
  '/laboratory-import-branches': {
    path: '/laboratory-import-branches',
    component: () => import('@/components/Laboratories/LaboratoryImportBranches'),
  },
  '/laboratory-cities': {
    path: '/laboratory-cities',
    component: () => import('@/pages/LaboratoryCities'),
  },
  '/laboratory-service': {
    path: '/laboratory-service',
    component: () => import('@/pages/LaboratoryService'),
  },
  '/laboratory-orders': {
    path: '/laboratory-orders',
    component: () => import('@/pages/LaboratoryOrders'),
  },
  '/laboratory-order-info/:id': {
    path: '/laboratory-order-info/:id',
    component: () => import('@/pages/LaboratoryOrderInfo'),
    props(route) {
      return { orderId: route.params.id };
    },
  },
  '/prices': {
    path: '/prices',
    component: () => import('@/pages/Prices'),
  },
  '/gluing-services': {
    path: '/gluing-services',
    component: () => import('@/pages/GluingServices'),
  },
  '/non-glued-services': {
    path: '/non-glued-services',
    component: () => import('@/pages/NonGluedServices'),
  },
  '/consultations': {
    path: '/consultations',
    component: () => import('@/pages/Consultations'),
    props(route) {
      const status = parseInt(route.query.status, 10);
      // eslint-disable-next-line no-restricted-globals
      return { status: isNaN(status) ? null : status };
    },
  },
  '/consultations-control': {
    path: '/consultations-control',
    component: () => import('@/pages/ConsultationsControl'),
  },
  '/sms': {
    path: '/sms',
    component: () => import('@/pages/SmsNew'),
  },
  '/informational-message': {
    path: '/informational-message',
    component: () => import('@/pages/InfoMessage'),
  },
  '/diseases': {
    path: '/diseases',
    component: () => import('@/pages/Diseases'),
  },
  '/promocodes': {
    path: '/promocodes',
    component: () => import('@/pages/PromocodesNew'),
  },
  '/schedule': {
    path: '/schedule',
    component: () => import('@/pages/ScheduleNew'),
  },
  '/specializations': {
    path: '/specializations',
    component: () => import('@/pages/Specializations'),
  },
  '/analysis': {
    path: '/analysis',
    component: () => import('@/pages/Analysis'),
  },
  '/knowledge-base': {
    path: '/knowledge-base',
    component: () => import('@/pages/KnowledgeBase'),
  },
  '/knowledge-base/:sectionName': {
    path: '/knowledge-base/:sectionName',
    component: () => import('@/components/KnowledgeBase/KnowledgeBaseSectionPage'),
    props(route) {
      return { sectionName: route.params.sectionName };
    },
  },
  '/users': {
    path: '/users',
    component: () => import('@/pages/Users'),
  },
  '/policy-list': {
    path: '/policy-list',
    component: () => import('@/pages/PolicyList'),
  },
  '/documents': {
    path: '/documents',
    component: () => import('@/pages/Documents'),
  },
  '/legal-entities': {
    path: '/legal-entities',
    component: () => import('@/pages/LegalEntities'),
  },
  '/legal-entities/create': {
    path: '/legal-entities/create',
    component: () => import('@/pages/LegalEntity'),
    props() {
      return { isCreate: true };
    },
  },
  '/legal-entities/:legalPersonId': {
    path: '/legal-entities/:legalPersonId',
    component: () => import('@/pages/LegalEntity'),
    props(route) {
      return { legalPersonId: route.params.legalPersonId };
    },
  },
  '/legal-entities/import/:legalPersonId': {
    path: '/legal-entities/import/:legalPersonId',
    component: () => import('@/components/LegalEntities/LegalEntityReestrImport'),
    props(route) {
      return { legalPersonId: route.params.legalPersonId };
    },
  },
  '/task-tracker': {
    path: '/task-tracker',
    component: () => import('@/pages/TaskTracker'),
  },
  '/roles': {
    path: '/roles',
    component: () => import('@/pages/Roles'),
  },
  '/rules': {
    path: '/rules',
    component: () => import('@/pages/rules/Rules'),
  },
  '/rule/:id': {
    path: '/rule/:id',
    component: () => import('@/pages/rules/Rule'),
    props(route) {
      return { reviewId: route.params.id };
    },
  },
  '/pages': {
    path: '/pages',
    component: () => import('@/pages/Pages'),
  },
  '/remote-monitoring/tasks': {
    path: '/remote-monitoring/tasks',
    component: () => import('@/pages/RemoteMonitoringTasks'),
  },

  // '/remote-monitoring/tags/': {
  //   path: '/remote-monitoring/tags/',
  //   component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringTemplateTags'),
  // },

  '/remote-monitoring/indicator-trends/': {
    path: '/remote-monitoring/indicator-trends/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringIndicatorTrends'),
  },

  '/remote-monitoring/indicator-trends/create/': {
    path: '/remote-monitoring/indicator-trends/create/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringIndicatorTrendsCreate'),
  },

  '/remote-monitoring/indicator-trends/view/:id/': {
    path: '/remote-monitoring/indicator-trends/view/:id/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringIndicatorTrendsView'),
  },

  '/remote-monitoring/stats': {
    path: '/remote-monitoring/stats',
    component: () => import('@/pages/RemoteMonitoringStats'),
  },

  // Стр. таблицы мониторингов для модуля Дист. Мониторинга
  '/remote-monitoring/monitorings-table/': {
    path: '/remote-monitoring/monitorings-table/',
    component: () => import('@/pages/RemoteMonitoringMonitoringsTable'),
  },

  // Стр.Списка Анкет Дист.Мониторинга
  '/remote-monitoring/questionnaires': {
    path: '/remote-monitoring/questionnaires',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringQuestionnaires'),
  },

  // Стр. Анкеты Дист.Мониторинга
  '/remote-monitoring/questionnaires/view/:id': {
    path: '/remote-monitoring/questionnaires/view/:id',
    component: () => import(
      '@/pages/RemoteMonitoringAdmin/RemoteMonitoringQuestionnairesView'
    ),
    props(route) {
      return { id: route.params.id };
    },
  },

  // Учет регистрационных удостоверений
  '/medical-devices/registration-sertificates': {
    path: '/medical-devices/registration-sertificates',
    component: () => import('@/pages/MedicalDevices/RegistrationSertificates'),
  },

  // Стр. Создания Анкеты Дист.Мониторинга
  '/remote-monitoring/questionnaires/add/': {
    path: '/remote-monitoring/questionnaires/add/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringQuestionnairesAdd'),
  },

  // Стр. Списка индикаторов Дист.Мониторинга
  '/remote-monitoring/indicators/': {
    path: '/remote-monitoring/indicators/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringIndicators'),
  },

  // Стр. Создания индикатора Дист.Мониторинга
  '/remote-monitoring/indicators/add/': {
    path: '/remote-monitoring/indicators/add/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringIndicatorsAdd'),
  },

  // Стр. Индикатора Дист.Мониторинга
  '/remote-monitoring/indicators/view/:id': {
    path: '/remote-monitoring/indicators/view/:id',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringIndicatorsView'),
    props(route) {
      return { id: route.params.id };
    },
  },

  // Стр. Шаблонов правил Дист.Мониторинга
  '/remote-monitoring/scoring-rules/': {
    path: '/remote-monitoring/scoring-rules/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringScoringRules'),
  },

  // Стр. Шаблонов сообщени Дист. Мониторинга
  '/remote-monitoring/messages-templates/': {
    path: '/remote-monitoring/messages-templates/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringMessagesTemplates'),
  },

  // Стр. Шаблонов Дист. Мониторинга
  '/remote-monitoring/templates/': {
    path: '/remote-monitoring/templates/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringTemplates'),
  },

  // Стр. Шаблонов Дист. Мониторинга
  '/remote-monitoring/templates/add/': {
    path: '/remote-monitoring/templates/add/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringTemplatesAdd'),
  },

  // Стр. Шаблонов Дист. Мониторинга
  '/remote-monitoring/templates/view/:id/': {
    path: '/remote-monitoring/templates/view/:id/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringTemplatesView'),
    props(route) {
      return { id: route.params.id };
    },
  },

  // Стр. таскок Дист. Мониторинга
  '/remote-monitoring/tasks-list/': {
    path: '/remote-monitoring/tasks-list/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringTasks'),
  },

  // Стр. Единиц измерения индикатора Дист.Мониторинга
  '/remote-monitoring/indicators/units/': {
    path: '/remote-monitoring/indicators/units/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringIndicatorUnits'),
  },

  '/patient-appointment': {
    path: '/patient-appointment',
    component: () => import('@/pages/PatientAppointment'),
  },
  '/referral-duty-consultation-import': {
    path: '/referral-duty-consultation-import',
    component: () => import('@/components/Consultations/ReferralDutyConsultationImport'),
  },
  '/queues': {
    path: '/queues',
    component: () => import('@/pages/Queues'),
  },
  '/queues/tags': {
    path: '/queues/tags',
    component: () => import('@/components/Queues/QueuesTags'),
  },
  '/insurance-import-list': {
    path: '/insurance-import-list',
    component: () => import('@/pages/InsuranceImportList'),
  },
  '/settings': {
    path: '/settings',
    component: () => import('@/pages/Settings'),
  },
  '/banner-management': {
    path: '/banner-management',
    component: () => import('@/pages/BannerManagement'),
  },
  '/stories': {
    path: '/stories',
    component: () => import('@/pages/Stories/StoriesList'),
  },
  '/stories/story-create/': {
    path: '/stories/story-create/',
    component: () => import('@/pages/Stories/StoryCreate'),
  },
  '/stories/story-info/:id': {
    path: '/stories/story-info/:id',
    component: () => import('@/pages/Stories/StoryInfo'),
    props(route) {
      return { storyId: route.params.id };
    },
  },

  // Учет мед. приборов
  '/medical-devices': {
    path: '/medical-devices',
    component: () => import('@/pages/MedicalDevices/MedicalDevices'),
  },

  // Модели мед. приборов
  '/medical-devices/models': {
    path: '/medical-devices/models',
    component: () => import('@/pages/MedicalDevices/MedicalDevicesModels'),
  },

  // Выдача \ прием мед.приборов
  '/remote-monitoring/medical-devices-control': {
    path: '/remote-monitoring/medical-devices-control',
    component: () => import('@/pages/MedicalDevices/MedicalDevicesControl'),
  },

  // Анализатор КТГ
  '/ctg-analizator-result': {
    path: '/ctg-analizator-result',
    component: () => import('@/pages/CtgAnalizatorPage'),
  },

  '/remote-monitoring/import': {
    path: '/remote-monitoring/import',
    component: () => import('@/pages/RemoteMonitoringImport'),
  },
};

export const BI_NOT_RECIEVED_INDICATOR_TITLE = [
  { key: 'withoutMeasurementQuantity', title: 'Нет ответа' },
  { key: 'refusedQuantity', title: 'Отказ от мониторинга' },
];

export const BI_DATA_COLLECTION_METHODS = [
  { key: 'colletctedWithCallCenterQuantity', title: 'Колл-центр' },
  { key: 'colectedWithRobotQuantity', title: 'Робот' },
  { key: 'colectedWithApplicationQuantity', title: 'Приложение' },
  { key: 'colletctedWithCtgQuantity', title: 'КТГ' },
  { key: 'colectedWithTonometrQuantity', title: 'Тонометр' },
  { key: 'colectedWithGlucometrQuantity', title: 'Глюкометр' },
  { key: 'withoutSpecifiedCollector', title: 'Не указано' },
];

export const BI_RECEIVED_INDICATORS = [
  { key: 'withinNormsQuantity', title: 'В норме ' },
  { key: 'outsideNormsQuantity', title: 'Не в норме ' },
];

export const BI_STATUSES = {
  NOT_RECIEVE_DATA: 0,
  REACTION: 1,
  WARNING: 3,
  NOT_AVAILABLE: 4,
  REFUSE: 5,
};

export const shortcutDays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Cб'];

export const shortcutDaysForCalendars = [
  'Пн',
  'Вт',
  'Ср',
  'Чт',
  'Пт',
  'Cб',
  'Вс',
];

export const shortcutMonths = [
  'Янв',
  'Фев',
  'Мар',
  'Апр',
  'Мая',
  'Июн',
  'Июл',
  'Авг',
  'Сен',
  'Окт',
  'Ноя',
  'Дек',
];

export const calendarMonths = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const DEFAULT_PERIOD_DAYS_COUNT = 20;

export const BI_CELL_TYPES = [
  {
    id: 1,
    title: 'Вызов скорой помощи',
    color: '#FF4646',
    border: 'transparent',
  },
  {
    id: 2,
    title: 'Требуют внимания',
    color: '#F89217',
    border: 'transparent',
  },
  {
    id: 3,
    title: 'Не переданы данные',
    color: '#B1B1B1',
    border: 'transparent',
  },
  {
    id: 4,
    title: 'Показатели в норме',
    color: '#DCECD9',
    border: 'transparent',
  },
  {
    id: 5,
    title: 'Отказ от мониторинга',
    color: '#B51919',
    border: 'transparent',
  },
  {
    id: 6,
    title: 'Реагирование - идёт дежурная консультация',
    color: '#FFFFFF',
    border: '#E21F1F',
  },
  {
    id: 7,
    title: 'Ожидается передача показателей',
    color: '#FFFFFF',
    border: '#B1B1B1',
  },
];

export const REACTION__CELL_TYPES = [
  { id: 1, title: 'Реагирование дежурного врача', color: '#E21F1F' },
];

export const CONSULTATION_TAGS = [
  { id: 1, title: 'Вызов скорой помощи', color: '#E14D4D' }, // Красное
  { id: 2, title: 'Требуется актив лечащего врача', color: '#FFD056' }, // Жетлое
  { id: 3, title: 'Помощь не требуется', color: '#A8E19E' }, // Зеленый
  { id: 4, title: 'Пациент не вышел на связь', color: '#BABABA' }, // Серый
];

export const EVENT_TYPES = [
  {
    id: 0,
    title: 'Не переданы данные',
    color: '#B1B1B1',
    class: 'nodata-day',
  },
  {
    id: 1,
    title: 'Реагирование',
    color: '#FF4646',
    class: 'high-day',
  },
  {
    id: 2,
    title: 'Отказ от мониторинга',
    color: '#B51919',
    class: 'refused',
  },
  {
    id: 3,
    title: 'Требуют внимания',
    color: '#F89217',
    class: 'medium-day',
  },
  {
    id: 4,
    title: 'Пациент не выходит на связь',
    color: '#B1B1B1',
    class: 'patient-not-available',
  },
  {
    id: 5,
    title: 'Вызов скорой',
    color: '#FF4646',
    class: 'high-day',
  },
  {
    id: 7,
    title: 'Консультация в работе',
    color: '#347AFB',
    class: 'in-work',
  },
  {
    id: 8,
    title: 'Консультация завершена',
    color: '#FF4646',
    class: 'high-day',
  },
  {
    id: 9,
    title: 'Показатели в норме',
    color: '#DCECD9',
    class: 'good-day',
  },
];

export const SIGNS_AGENCY_AGREEMENT = [
  {
    value: 0,
    text: 'Агентский договор',
  },
  {
    value: 1,
    text: 'Телемед',
  },
  {
    value: 2,
    text: 'Очная помощь',
  },
];

export const PRICE_TYPES = [
  {
    id: 0,
    title: 'ДМС',
  },
  {
    id: 1,
    title: 'Индивидуальный',
  },
  {
    id: 2,
    title: 'Коробочная версия',
  },
  {
    id: 3,
    title: 'Телемедицина',
  },
];

export const TASK_STATUSES = {
  NEW: 0,
  CANCELED: 1,
  FINISHED: 2,
  CLOSED: 3,
  PROCESS: 4,
  NOTRESPONSE: 5,
  SUSPENDED: 6,
};

export const BANNER_STYLE = {
  WHITE: 0,
  PURPLE: 1,
  ORANGE: 2,
  BLUE: 3,
  GREEN: 4,
  PINK: 5,
  AQUAMARINE: 6,
};

export const BANNER_STYLE_INFO = [
  {
    id: 0,
    key: 'White',
    bgColor: '#fff',
    textColor: '#000',
    title: 'Белый',
  },
  {
    id: 1,
    key: 'Purple',
    bgColor: 'purple',
    textColor: '#fff',
    title: 'Фиолетовый',
  },
  {
    id: 2,
    key: 'Orange',
    bgColor: 'orange',
    textColor: '#fff',
    title: 'Оранжевый',
  },
  {
    id: 3,
    key: 'Blue',
    bgColor: 'blue',
    textColor: '#fff',
    title: 'Синий',
  },
  {
    id: 4,
    key: 'Green',
    bgColor: 'green',
    textColor: '#000',
    title: 'Зеленый',
  },
  {
    id: 5,
    key: 'Pink',
    bgColor: 'pink',
    textColor: '#fff',
    title: 'Розовый',
  },
  {
    id: 6,
    key: 'Aquamarine',
    bgColor: 'aquamarine',
    textColor: '#000',
    title: 'Аквамариновый',
  },
];

export const RM_ADMIN_INDICATOR_TYPES = [
  {
    id: 0,
    title: 'Выбор из списка',
  },
  {
    id: 1,
    title: 'Ввод числа',
  },
  {
    id: 2,
    title: 'Ввод текста',
  },
  {
    id: 3,
    title: 'Ответ да/нет',
  },
  {
    id: 4,
    title: 'График',
  },
  {
    id: 5,
    title: 'Файл',
  },
  {
    id: 6,
    title: 'Среднее значение',
  },
];

export const RM_ADMIN_INDICATOR_INPUT_TYPE = [
  {
    id: 0,
    title: 'Ручной ввод данных',
  },
  {
    id: 1,
    title: 'Сбор устройством КТГ',
  },
  {
    id: 2,
    title: 'Сбор устройством тонометр',
  },
  {
    id: 5,
    title: 'Сбор устройством глюкометр',
  },
  {
    id: 6,
    title: 'Сбор устройством холтер ЭКГ',
  },
];

export const RM_ADMIN_INDICATOR_CANONICAL_NAME = [
  {
    id: 1,
    title: 'КТГ TOCO',
  },
  {
    id: 2,
    title: 'КТГ FHR',
  },
  {
    id: 3,
    title: 'КТГ Толчки',
  },
  {
    id: 4,
    title: 'КТГ Звук',
  },
  {
    id: 5,
    title: 'КТГ Время',
  },
  {
    id: 6,
    title: 'Тонометр AnD дисталическое давление',
  },
  {
    id: 7,
    title: 'Тонометр AnD систолическое давление',
  },
  {
    id: 8,
    title: 'Тонометр AnD пульс',
  },
  {
    id: 9,
    title: 'Уровень глюкозы в крови',
  },
  {
    id: 10,
    title: 'id сессии холтера ЭКГ',
  },
];

export const FREQUENCY_TYPE = [
  { title: 'Ежедневно', code: 0 },
  { title: 'Еженедельно', code: 1 },
  { title: 'Ежемесячно', code: 2 },
  { title: 'Ежеквартально', code: 3 },
  { title: 'Ежегодно', code: 4 },
  { title: 'Раз в 3 дня', code: 5 },
  { title: 'Раз в 2 дня', code: 6 },
  { title: 'Раз в 5 дней', code: 7 },
  { title: 'Раз в 10 дней', code: 8 },
];

export const RM_TASKS_PER_DAY = [
  {
    id: 0,
    value: 1,
    title: '1 раз в день',
  },
  {
    id: 1,
    value: 2,
    title: '2 раза в день',
  },
  {
    id: 2,
    value: 3,
    title: '3 раза в день',
  },
  {
    id: 3,
    value: 4,
    title: '4 раза в день',
  },
  {
    id: 4,
    value: 5,
    title: '5 раз в день',
  },
  {
    id: 5,
    value: 6,
    title: '6 раз в день',
  },
];

export const RM_CONSULTATION_TYPES = [
  {
    title: 'Дежурные консультации',
    id: 0,
  },
  {
    title: 'Консультации у лечещаго врача',
    id: 1,
  },
];

export const MONITORING_KIND = [
  {
    title: 'Мониторинг без определенной специфики',
    id: 0,
  },
  {
    title: 'Миниторинг для беременных',
    id: 1,
  },
  {
    title: 'Мониторинг для кардиологических заболеваний',
    id: 2,
  },
  {
    title: 'Мониторинг для онкологических заболеваний',
    id: 3,
  },
  {
    title: 'Мониторинг с холтером ЭКГ',
    id: 4,
  },
];

export const REMOTE_MONITORING_SUB_TASK_TYPE = [
  {
    id: 0,
    title: 'Отправить пуш сообщение',
  },
  {
    id: 1,
    title: 'Отправить смс сообщение',
  },
  {
    id: 2,
    title: 'Назначить звонок робота',
  },
  {
    id: 3,
    title: 'Назначить задачу колл-центру',
  },
  {
    id: 4,
    title: 'Активировать заполнение в мобильном приложении пациента',
  },
];

export const DEFAULT_CALLING_TIME_RM = ['08:00', '20:00'];

export const MEDICAL_DEVICE_TYPE_ENUM = {
  OTHER: 0,
  CTG: 1,
  TOMOMETER: 2,
  GLUCOMETER: 3,
  ECG: 4,
};

export const MEDICAL_DEVICE_TYPE = [
  {
    id: 0,
    title: 'Другое устройство',
    canonical: 'Other',
  },
  {
    id: 1,
    title: 'Фетальный монитор (КТГ)',
    canonical: 'FetalMonitor',
  },
  {
    id: 2,
    title: 'Тонометр',
    canonical: 'Tonometer',
  },
  {
    id: 3,
    title: 'Глюкометр',
    canonical: 'Glucometer',
  },
  {
    id: 4,
    title: 'Холтер ЭКГ',
    canonical: 'EcgHalter',
  },
];

export const MEDICAL_DEVICE_STATUS = [
  {
    id: 0,
    title: 'Прибор доступен к выдаче',
  }, // Прибор доступен к выдаче
  {
    id: 1,
    title: 'Прибор у пациента',
  }, // Прибор у пациента
  {
    id: 2,
    title: 'Добавлен',
  },
];

export const MEDICAL_DEVICE_STATUS_NAMES = {
  AVAILABLE: 0,
  ISSUED: 1,
  ADDED: 2,
};

export const MONITORING_DEVICE_STATUS = {
  NOTSUPPORTED: 0, // Прибор не предназначен
  TOISSUE: 1, // Прибор должен быть выдан, но еще не выдан
  ISSUED: 2, // Прибор выдан пользователю
  WITHDRAWED: 3, // Прибор сдан пользователем
};

export const MONITORING_DEVICE_STATUS_STRING = {
  NOTSUPPORTED: 'NotSupported', // Прибор не предназначен
  TOISSUE: 'ToIssue', // Прибор должен быть выдан, но еще не выдан
  ISSUED: 'Issued', // Прибор выдан пользователю
  WITHDRAWED: 'Withdrawed', // Прибор сдан пользователем
};

export const MONITORING_DEVICE_STATUS_FULL = [
  {
    title: 'Прибор не предназначен для выдачи',
    value: 'NotSupported',
  },
  {
    title: 'Прибор должен быть выдан, но еще не выдан',
    value: 'ToIssue',
  },
  {
    title: 'Прибор выдан пользователю',
    value: 'Issued',
  },
  {
    title: 'Прибор сдан пользователем',
    value: 'Withdrawed',
  },
];

export const OFFERS = [
  {
    id: 0,
    title: '(Для взрослого) Оферта',
    requestKey: 'offer',
  },
  {
    id: 1,
    title: '(Для ребенка) Оферта',
    requestKey: 'childOffer',
  },
  {
    id: 2,
    title:
      '(Для взрослого) Согласие на предоставление персональных данных в клинику',
    requestKey: 'personalDataAgreement',
  },
  {
    id: 3,
    title:
      '(Для ребенка) Согласие на предоставление персональных данных в клинику',
    requestKey: 'childPersonalDataAgreement',
  },
  {
    id: 4,
    title:
      '(Для взрослого) Информированное добровольное согласие на медицинское вмешательство',
    requestKey: 'medicalInterventionAgreement',
  },
  {
    id: 5,
    title:
      '(Для ребенка) Информированное добровольное согласие на медицинское вмешательство',
    requestKey: 'childMedicalInterventionAgreement',
  },
];

export const HASHTAGS = [
  {
    title: 'ФИО Клиента',
    key: '##FIO_CLIENT##',
  },
  {
    title: 'День Рождения',
    key: '##CLIENT_BIRTHDAY##',
  },
  {
    title: 'Текущая дата',
    key: '##CURRENT_DATE##',
  },
  {
    title: 'Название клиники',
    key: '##CLINIC_NAME##',
  },
  {
    title: 'Юр.лицо',
    key: '##LEGAL_ENTITY##',
  },
  {
    title: 'ФИО Доктора',
    key: '##FIO_DOCTOR##',
  },
  {
    title: 'ИНН',
    key: '##INN##',
  },
  {
    title: 'КПП',
    key: '##KPP##',
  },
  {
    title: 'ОГРН',
    key: '##OGRN##',
  },
];

export const CLINIC_EDITOR_TOOLBAR = [
  ['bold', 'italic', 'underline'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [
    { align: '' },
    { align: 'center' },
    { align: 'right' },
    { align: 'justify' },
  ],
  [{ color: [] }],
];

export const REMOTE_MONITORING_STATUS = {
  ACTIVE: 0, // Активе
  CONCLUDED: 1, // Завершен
  REFUSED: 2, // Отказ от мониторинга
  CANCELED: 3, // Отменен
  SUSPENDED: 4, // Приостановлен
};

export const REMOTE_MONITORING_STATUS_STRING = {
  ACTIVE: 'Active', // Активе
  CONCLUDED: 'Concluded', // Завершен
  REFUSED: 'Refused', // Отказ от мониторинга
  CANCELED: 'Cancelled', // Отменен
  SUSPENDED: 'Suspended', // Приостановлен
  CREATED: 'Created',
};

export const DISEASE_STATES = {
  ACTIVE: 0, // Активен
  CONCLUDED: 1, // Завершен
};

export const REASONS_GETTING_CONTROL_CONSULTATIONS = {
  0: {
    type: 'Oms',
    title: 'ОМС',
  },
  1: {
    type: 'LittleCharacters',
    title: 'Символы до 15',
  },
  2: {
    type: 'ThirdPerson',
    title: 'Третье лицо',
  },
  3: {
    type: 'FirstConsultation',
    title: 'Первая консультация',
  },
  4: {
    type: 'FirstConsultationOfTheYear',
    title: 'Год с консультации',
  },
};

export const ACCOUNT_TYPES = [
  {
    id: 0,
    title: 'Выбрать существующую',
  },
  {
    id: 1,
    title: 'Создать новую',
  },
];

export const MEDICAL_DEVICE_USAGE_TYPE = [
  {
    id: 0,
    value: 'Doctis',
  },
  {
    id: 1,
    value: 'Rostec',
  },
];

export const CLIENT_TYPES = [
  {
    id: 0,
    title: 'Неизвестно',
  },
  {
    id: 1,
    title: 'Android приложение Doctis',
  },
  {
    id: 2,
    title: 'iOS приложение Doctis',
  },
  {
    id: 3,
    title: 'Веб-приложение Doctis для пациента',
  },
  {
    id: 4,
    title: 'CRM',
  },
  {
    id: 5,
    title: 'Веб-приложение Doctis для врача',
  },
  {
    id: 11,
    title: 'Android приложение FoodFessional Generation',
  },
  {
    id: 12,
    title: 'iOS приложение FoodFessional Generation',
  },
  {
    id: 13,
    title: 'Теле2',
  },
  {
    id: 101,
    title: 'Внешний сервис',
  },
  {
    id: 102,
    title: 'СМЭВ',
  },
  {
    id: 103,
    title: 'Диспетчер',
  },
  {
    id: 104,
    title: 'Дистанционный мониторинг',
  },
  {
    id: 111,
    title: 'ЕЦП.МИС',
  },
];

export const BANNER_TYPE = [
  {
    id: 1,
    key: 'promocode',
    title: 'Промокод',
  },
  {
    id: 2,
    key: 'link',
    title: 'Ссылка',
  },
  {
    id: 10,
    key: 'doctors',
    title: 'Врачи',
  },
  {
    id: 11,
    key: 'specializations',
    title: 'Специализации',
  },
];

export const BANNER_CATEGORY = [
  // {
  //   id: 0,
  //   key: 'Promotions',
  //   title: 'Акции',
  // },
  // {
  //   id: 1,
  //   key: 'Doctors',
  //   title: 'Врачи',
  // },
  {
    id: 2,
    key: 'LandingMain',
    title: 'Основной лендинг',
    colored: true,
    order: 1,
  },
  // {
  //   id: 3,
  //   key: 'LandingSecondary',
  //   title: 'Вторичный лендинг',
  //   colored: true,
  //   order: 2,
  // },
  {
    id: 4,
    key: 'AppMain',
    title: 'Дашборд', // все кроме дашборда - цветастые
    colored: false,
    order: 3,
  },
  {
    id: 5,
    key: 'Tmcenters',
    title: 'Телемедицинские центры',
    colored: true,
    order: 4,
  },
  {
    id: 6,
    key: 'Laboratory',
    title: 'Лаборатории',
    colored: true,
    order: 5,
  },
  {
    id: 7,
    key: 'MobileApp',
    title: 'Мобильное приложение',
    colored: true,
    order: 6,
  },
];

export const BANNER_CLIENT_TYPE = [
  {
    id: 0,
    key: 'WebMobile',
    title: 'Веб-смартфон',
  },
  // {
  //   id: 1,
  //   key: 'WebMedium',
  //   title: 'Сайт-средний (medium)',
  // },
  {
    id: 2,
    key: 'WebDesktop',
    title: 'Веб-ПК',
  },
  // {
  //   id: 3,
  //   key: 'Mobile',
  //   title: 'Мобильное устройство',
  // },
  {
    id: 4,
    key: 'Android',
    title: 'Android',
    isMobile: true,
  },
  {
    id: 5,
    key: 'Ios',
    title: 'iOS',
    isMobile: true,
  },
];

export const REMOTE_MONITORING_TASK_STATE = [
  { title: 'Будущая', id: 0 },
  { title: 'Текущая', id: 1 },
  { title: 'Завершенная', id: 2 },
  { title: 'Приостановленная', id: 3 },
];

export const REMOTE_MONITORING_EXECUTORS = [
  {
    value: -1,
    title: 'Нет исполнителя',
  },
  {
    value: 0,
    title: 'Сотрудник КЦ',
  },
  {
    value: 1,
    title: 'Пациент',
  },
  {
    value: 2,
    title: 'Врач',
  },
  {
    value: 3,
    title: 'Сотрудник мед. службы "РТ-Доктис"',
  },
  {
    value: 4,
    title: 'Сотрудник регистратуры мед. организации',
  },
  {
    value: 5,
    title: 'Робот',
  },
  {
    value: 6,
    title: 'СМС',
  },
  {
    value: 7,
    title: 'Пуш',
  },
];

export const DOCTOR_INACTIVE_REASONS = [
  {
    id: 0,
    title: 'Новый врач',
  },
  {
    id: 1,
    title: 'Отказался работать',
  },
  {
    id: 2,
    title: 'Отключен за нарушения',
  },
  {
    id: 3,
    title: 'Дубль',
  },
  {
    id: 4,
    title: 'Уволен',
  },
];

export const EVENT_TYPES_ENUM = {
  NODATA: 0,
  DANGER: 1,
  REFUSE: 2,
  ATTENTION: 3,
  DONTANSWER: 4,
  AMBULANCE: 5,
  INWORK: 7,
  COMPLETE: 8,
  NORMAL: 8,
};

export const REMOTE_MONITORING_SCORING_RESULT = {
  GOOD: 0,
  NEUTRAL: 1,
  ATTENTION: 2,
  DANGER: 3,
};

export const TIME_ZONES = [
  {
    id: 0,
    timeZone: '+0',
  },
  {
    id: 1,
    timeZone: '+1',
  },
  {
    id: 2,
    timeZone: '+2',
  },
  {
    id: 3,
    timeZone: '+3',
  },
  {
    id: 4,
    timeZone: '+4',
  },
  {
    id: 5,
    timeZone: '+5',
  },
  {
    id: 6,
    timeZone: '+6',
  },
  {
    id: 7,
    timeZone: '+7',
  },
  {
    id: 8,
    timeZone: '+8',
  },
  {
    id: 9,
    timeZone: '+9',
  },
  {
    id: 10,
    timeZone: '+10',
  },
  {
    id: 11,
    timeZone: '+11',
  },
  {
    id: 12,
    timeZone: '+12',
  },
  {
    id: 13,
    timeZone: '+13',
  },
];

export const IMPORT_TARGETS = {
  DOCTIS: 0,
  RT_HEALTH: 1,
  GOSTELEMED: 2,
};
export const INDICATOR_TYPE = {
  NUMBER: 1, // числовое значение
  AVERAGE: 6, // среднее значение
};
export const ROLE = {
  // добавить по мере необходимости
  DIS_MONITORING: 'dis.monitoring',
  DM_MEDICAL_WORKER: 'medical.worker.dis.monitoring',
  GOV_STAFF_DEVICE: 'gov.staff.device',
  GOV_EXTRA_ADMIN: 'gov.extra.admin.mo.full',
  GOV_ADMIN_REGION: 'gov.admin.region',
  GOV_ADMIN_STAFF: 'gov.admin.soft',
  GOV_SMALL_ADMIN: 'gov.doctor.small.admin.mo',
};

export const COMPARSION_OPERATORS_FOR_NUMERIC = [
  {
    value: 'Equal',
    name: 'Равно',
  },
  {
    value: 'NotEqual',
    name: 'Не равно',
  },
  {
    value: 'GreaterThan',
    name: 'Больше чем',
  },
  {
    value: 'LessThan',
    name: 'Меньше чем',
  },
  {
    value: 'GreaterThanOrEqual',
    name: 'Больше Или Равно',
  },
  {
    value: 'LessThanOrEqual',
    name: 'Меньше Или Равно',
  },
];
export const COMPARSION_OPERATORS_FOR_STRING = [
  {
    value: 'Equal',
    name: 'Равно',
  },
  {
    value: 'NotEqual',
    name: 'Не равно',
  },
];

export const COMPARSION_OPERATORS_FOR_ARRAY = [
  {
    value: 'ContainedIn',
    name: 'Содержится в',
  },
];

export const CREATE_PATIENT_MODE = {
  ForIndemnity: 0,
  ForDoctis: 1,
};
