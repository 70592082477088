<template>
  <div class="page-dm-import">
    <div v-if="$store.state.Auth.user.doctorId" class="container-xl">
      <button type="primary" class="btn ml-auto btn-primary btn-sm page-dm-import__lk-button" @click="openDoctorAuth"> В ЛК врача </button>
    </div>
    <d-remote-monitoring-import class="page-dm-import__monitoring" />
  </div>
</template>

<script>
import { APP_DOCTOR_URL } from '@/app.config';
import { showMessage } from '@/helpers/utils';

export default {
  name: 'RemoteMonitoringImport',
  page: {
    title: 'CRM Doctis - Импорт дистанционный мониторинг',
  },
  methods: {
    async openDoctorAuth() {
      try {
        if (this.$store.state.Auth.user.doctorId) {
          const id = this.$store.state.Auth.user.doctorId;
          const { token, refreshToken } = await this.$store.dispatch(this.$types.DOCTOR_AUTH_TOKEN_FETCH, id);
          const doctorUrl = APP_DOCTOR_URL;
          const url = `${doctorUrl}auth?token=${token}&refreshToken=${refreshToken}`;
          window.location.href = url;
        }
      } catch (error) {
        showMessage({
          type: 'error',
          title: 'Ошибка',
          message: 'Не удалось авторизовать врача',
        });
      }
    },
  },
};
</script>

<style lang="scss">
  .page-dm-import {
    width: 100%;
    overflow: auto;
    .container-xl {
      position: relative;
    }
    &__monitoring {
      position: relative;
    }
    &__lk-button {
      position: absolute;
      right: 16px;
      z-index: 10;
      top: 29px;
    }
  }
  .page-dm-import__monitoring .btn.btn-primary {
    margin-right: 116px !important;
  }
</style>
